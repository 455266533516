module.exports = {
  language: '中文',
  formLabelFirstName: 'First Name',
  formLabelLastName: 'Last Name',
  formLabelContact: 'Contact Number',
  formLabelEmail: 'Email Address',
  formLabelSubject: 'Subject',
  formLabelMessage: 'Your Message',
  formSubmit: 'Submit',
  successMessage: 'The message has been sent successfully!',
  invalidFieldsMessage: 'Please complete all required fields.',
};
